<template>
	<div>
		<p class="title">房子信息</p>
		<el-descriptions v-if="propInfo && Object.keys(propInfo).length > 0" :column="3" border
			:labelStyle="{'width': '120px'}">
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-person"></i>
					房东
				</template>
				{{username}}
			</el-descriptions-item>
			<el-descriptions-item :span="2">
				<template slot="label">
					<i class="iconfont icon-dizhi"></i>
					地址
				</template>
				{{propInfo.address}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-zulindanwei-xian"></i>
					租赁情况
				</template>
				<el-tag v-if="propInfo.is_leasing" type="success" size="medium">出租中</el-tag>
				<el-tag v-else type="danger" size="medium">未租</el-tag>
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-yuezucheweifei"></i>
					月租
				</template>
				{{propInfo.is_leasing ? (propInfo.leaseInfo ? currencyFormatter(propInfo.leaseInfo.rent_per_month) : 'N/A') : 'N/A'}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-yajin"></i>
					押金
				</template>
				{{propInfo.is_leasing ? (propInfo.leaseInfo ? currencyFormatter(propInfo.leaseInfo.deposit) : 'N/A') : 'N/A'}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-chongwu"></i>
					宠物押金
				</template>
				{{propInfo.is_leasing ? (propInfo.leaseInfo ? currencyFormatter(propInfo.leaseInfo.pet_deposit) : 'N/A') : 'N/A'}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="iconfont icon-meijin"></i>
					管理费
				</template>
				{{propInfo.is_leasing ? (propInfo.leaseInfo ? currencyFormatter(propInfo.leaseInfo.mgmt_fee) : 'N/A') : 'N/A'}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<i class="el-icon-date"></i>
					租期
				</template>
				<span style="position: relative"
					v-if="propInfo.is_leasing && propInfo.leaseInfo">{{$dayjs(propInfo.leaseInfo.date_start).format('MM/DD/YYYY')}}&nbsp;&nbsp;&nbsp;&nbsp;<img
						src="@/assets/img/arrow-r.png" alt=""
						style="position: absolute;top: 5px">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{$dayjs(propInfo.leaseInfo.date_end).format('MM/DD/YYYY')}}
				</span>
				<span v-else>{{'N/A'}}</span>
			</el-descriptions-item>
		</el-descriptions>
		<el-row class="operation-bar">
			<el-col :span="4">
				<p class="title title1">收益情况</p>
			</el-col>
			<el-col :span="20" class="right-part">
				<el-radio-group v-model="radio" size="mini" class="pickrange" @change="handleSelection">
					<el-radio-button label="6">六个月</el-radio-button>
					<el-radio-button label="12">一年</el-radio-button>
					<el-radio-button label="24">二年</el-radio-button>
				</el-radio-group>
				<el-date-picker v-model="daterange" type="monthrange" range-separator="至"
					start-placeholder="开始月份" end-placeholder="结束月份" style="margin-right: 20px"
					@change="dateChange">
				</el-date-picker>
				<el-button type="info" icon="el-icon-search" @click='handleSearch'>搜索</el-button>
			</el-col>
		</el-row>
		<div class="chart-wrap" v-if="isShow">
			<v-chart v-loading="loading" class="chart" ref="chart" :auto-resize="true" :option="option"
				style="height:280px;width:100%;" />
		</div>

	</div>
</template>

<script>
import {graphic} from 'echarts/core';
export default {
	name: 'GeneralDetail',
	props: ['pid'],
	created() {
		this.getPropertInfo();
		this.getIncome(this.radio);
	},
	mounted() {
		window.addEventListener('resize', this.resizeTheChart);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeTheChart);
	},
	data() {
		return {
			username: window.sessionStorage.getItem('username'),
			loading: false,
			isShow: false,
			daterange: null,
			propInfo: null,
			radio: 6,
			chartStyle: {
				height: '280px',
				width: '100%'
			},
			option: {
				tooltip: {
					trigger: 'axis'
				},
				xAxis: {
					type: 'category',
					boundaryGap: false,
					data: []
				},
				yAxis: {
					type: "value",
					scale: true,
				},
				grid: {
					left: '37px',
					top: '50px',
					right: '25px',
					bottom: '50px'
				},
				series: []
			},
		};
	},
	methods: {
		async getPropertInfo() {
			const {data: res} = await this.$http.get('client/propinfo/' + this.pid);
			if (res.meta.status !== 200) return this.$message.error('获取房产信息失败！');
			this.propInfo = res.data;
		},
		resizeTheChart() {
			if (this.$refs && this.$refs.chart) {
				this.$refs.chart.resize();
			}
		},
		handleSelection(lable) {
			if (this.daterange) this.daterange = null;
			this.getIncome(lable);
		},
		dateChange() {
			if (!this.daterange) {
				this.radio = 6;
				this.getIncome(this.radio);
			}
		},
		handleSearch() {
			if (!this.daterange) return;
			this.radio = 0;
			this.getIncome(this.daterange);
		},
		async getIncome(time) {
			const queryInfo = {};
			let now = this.$dayjs().endOf('month');
			if (Array.isArray(time)) {
				queryInfo.startTime = time[0];
				queryInfo.endTime = this.$dayjs(time[1]).endOf('month').$d;
			} else {
				queryInfo.startTime = now.subtract(time - 1, 'month').startOf('month').$d;
				queryInfo.endTime = now.$d;
			}
			queryInfo.cid = window.sessionStorage.getItem('cid');
			this.loading = true;
			const {data: res} = await this.$http.get(`client/property/${this.pid}`, {params: queryInfo});
			const xAxisData = [];
			let startTime = queryInfo.startTime;
			while (startTime < queryInfo.endTime) {
				xAxisData.push(this.$dayjs(startTime).format('MM/YYYY'));
				startTime = this.$dayjs(startTime).add(1, 'month').$d;
			}
			this.option.xAxis.data = [...xAxisData];
			const seriesData = [];
			seriesData.push({
				name: res.data.address,
				data: [],
				type: 'line',
				areaStyle: {
					color: new graphic.LinearGradient(0, 0, 0, 1, [
						{
							offset: 0,
							color: 'rgba(224, 62, 76, .8)'
						},
						{
							offset: 1,
							color: 'rgba(255, 191, 0, 0.5)'
						}
					])
				},
				lineStyle: {
					color: '#F2994A'
				},
			});
			startTime = queryInfo.startTime;
			while (startTime < queryInfo.endTime) {
				let checks = res.data.monthly_checks.filter(check => new Date(check.sent_date) >= startTime && new Date(check.sent_date) <= this.$dayjs(startTime).endOf('month').$d);
				let checksAmount = checks.reduce((pre, cur) => pre + cur.check_amount, 0.00);
				if (checks.length === 0) seriesData[0].data.push('');
				else seriesData[0].data.push(checksAmount);
				startTime = this.$dayjs(startTime).add(1, 'month').$d;
			}
			this.option.series = [...seriesData];
			this.isShow = true;
			this.loading = false;
		}
	},
}
</script>

<style lang="less" scoped>
div {
	.title {
		font-size: 22px;
		line-height: 1.5;
		font-weight: 600;
		margin: 25px 0 20px;
	}
	.operation-bar {
		margin-top: 50px;
		.title1 {
			line-height: 40px;
			margin: 0;
		}
		.right-part {
			text-align: right;
			.pickrange {
				margin-right: 20px;
			}
		}
	}
}
</style>