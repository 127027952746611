<template>
	<div>
		<p class="title">账单详情</p>
		<el-row class="operation-bar bottom">
			<el-col :span="8">
				<span class="bill-type">账单名目</span>
				<el-select class="type" v-model="queryInfo.billType" placeholder="请选择"
					@change="handleSelChange" clearable>
					<el-option v-for="item in options" :key="item.value" :label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</el-col>
			<el-col :span="16" class="right-part">
				<span class="search-text">搜索范围</span>
				<el-date-picker v-model="daterange" type="daterange" range-separator="至"
					start-placeholder="开始时间" end-placeholder="结束时间" style="margin-right: 20px"
					@change="timeChange">
				</el-date-picker>
				<el-button type="warning" icon="el-icon-search" @click='getBillList'>搜索</el-button>
			</el-col>
		</el-row>
		<el-table :data="billList" stripe @sort-change="changeSort" style="width: 100%"
			v-loading="loading">
			<el-table-column type="index"></el-table-column>
			<el-table-column prop="bill_date" label="账单日期" sortable="custom" width="120"
				:formatter="formatDate">
			</el-table-column>
			<el-table-column prop="due_date" label="到期日" sortable="custom" width="120"
				:formatter="formatDate">
			</el-table-column>
			<el-table-column prop="bill_type" label="账单名目" width="245">
			</el-table-column>
			<el-table-column prop="bill_amount" label="金额" width="100" :formatter="usdFormatter">
			</el-table-column>
			<el-table-column prop="dl_url" label="PDF下载" width="80">
				<template slot-scope="scope">
					<span v-if="!scope.row.dl_url || scope.row.dl_url === 'N/A'">N/A</span>
					<el-link v-else type="primary" :href="scope.row.dl_url">下载
					</el-link>
				</template>
			</el-table-column>
			<el-table-column prop="note" label="备注" min-width="400">
			</el-table-column>
		</el-table>
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="queryInfo.currentPage" :page-sizes="[5, 10, 20, 50]"
			:page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="total">
		</el-pagination>
	</div>
</template>

<script>
export default {
	name: 'BillInfo',
	props: ['pid'],
	data() {
		return {
			options: [{
				value: 'Management fees',
				label: 'Management fees'
			}, {
				value: 'Cleaning and Maintenance',
				label: 'Cleaning and Maintenance'
			}, {
				value: 'Repairs',
				label: 'Repairs'
			}, {
				value: 'Leasing commissions',
				label: 'Leasing commissions'
			}, {
				value: 'Property Insurance',
				label: 'Property Insurance'
			}, {
				value: 'Property Taxes',
				label: 'Property Taxes'
			}, {
				value: 'Sale Tax',
				label: 'Sale Tax'
			}, {
				value: 'Remodel',
				label: 'Remodel'
			}, {
				value: 'HOA',
				label: 'HOA'
			}, {
				value: 'Utilities',
				label: 'Utilities'
			}, {
				value: 'Credit card process fee',
				label: 'Credit card process fee'
			}, {
				value: 'Legal and other professional fees',
				label: 'Legal and other professional fees'
			}, {
				value: 'Other fees',
				label: 'Other fees'
			}],
			daterange: null,
			queryInfo: {
				billType: '',
				date_start: null,
				date_end: null,
				currentPage: 1,
				pageSize: 10,
				order: '',
				prop: ''
			},
			billList: [],
			loading: false,
			total: 0
		};
	},
	created() {
		this.getBillList();
	},
	methods: {
		async getBillList() {
			this.loading = true;
			const {data: res} = await this.$http.get('client/propbills/' + this.pid, {params: this.queryInfo});
			if (res.meta.status !== 200) return this.$message.error('获取账单列表失败！');
			this.billList = res.data.bills;
			this.total = res.data.total;
			this.loading = false;
		},
		timeChange(value) {
			if (!value) {
				this.queryInfo.date_start = null;
				this.queryInfo.date_end = null;
				this.queryInfo.currentPage = 1;
				this.getBillList();
			} else {
				this.queryInfo.date_start = value[0];
				this.queryInfo.date_end = value[1];
			}
		},
		handleSelChange() {
			this.getBillList();
		},
		changeSort({order, prop}) {
			this.queryInfo.order = order;
			this.queryInfo.prop = prop;
			this.getBillList();
		},
		formatDate(r, c, v) {
			if (v) return this.$dayjs(v).format('MMM-DD-YYYY');
			return v;
		},
		handleSizeChange(newSize) {
			this.queryInfo.pageSize = newSize;
			this.getBillList();
		},
		handleCurrentChange(newPage) {
			this.queryInfo.currentPage = newPage;
			this.getBillList();
		}
	}
}
</script>

<style lang="less" scoped>
div {
	.title {
		font-size: 22px;
		line-height: 1.5;
		font-weight: 600;
		margin: 25px 0 20px;
	}
	.operation-bar {
		margin-top: 25px;
		.search-text,
		.bill-type {
			display: inline-block;
			font-size: 14px;
			font-weight: 600;
			margin-right: 14px;
		}
		.bill-type {
			line-height: 40px;
		}
		.title1 {
			line-height: 40px;
			margin: 0;
		}
		.right-part {
			text-align: right;
		}
	}
	.el-table {
		margin-top: 30px;
	}
	.bottom {
		margin-top: 50px;
	}
}
</style>