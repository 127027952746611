<template>
	<div>
		<el-row class="operation-bar">
			<el-col :span="4">
				<p class="title title1">房东租约情况</p>
			</el-col>
			<el-col :span="20" class="right-part">
				<span class="search-text">搜索范围</span>
				<el-date-picker v-model="daterange" type="daterange" range-separator="至"
					start-placeholder="开始时间" end-placeholder="结束时间" style="margin-right: 20px"
					@change="timeChange">
				</el-date-picker>
				<el-button type="info" icon="el-icon-search" @click='getLeaseInfo'>搜索</el-button>
			</el-col>
		</el-row>
		<el-table :data="leaseList" stripe @sort-change="changeSort" :highlight-current-row="true"
			v-loading="loading1">
			<el-table-column type="index"></el-table-column>
			<el-table-column label="起始时间" prop="date_start" sortable="custom">
				<template slot-scope="scope">
					{{$dayjs(scope.row.date_start).format('MMM-DD-YYYY') }}
				</template>
			</el-table-column>
			<el-table-column label="终止时间" prop="date_end" sortable="custom">
				<template slot-scope="scope">
					{{$dayjs(scope.row.date_end).format('MMM-DD-YYYY') }}
				</template>
			</el-table-column>
			<el-table-column label="月租" prop="rent_per_month" :formatter="usdFormatter"></el-table-column>
			<el-table-column label="月管理费" prop="mgmt_fee" :formatter="usdFormatter"></el-table-column>
			<el-table-column label="是否过期">
				<template slot-scope="scope">
					<el-tag v-if="isValidLease(scope.row)" type="success" size="medium">未过期</el-tag>
					<el-tag v-else type="danger" size="medium">已过期</el-tag>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="queryInfo.pagenum" :page-sizes="[5, 10, 20, 50]"
			:page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper"
			:total="total">
		</el-pagination>
		<el-row class="operation-bar bottom">
			<el-col :span="5">
				<p class="title title1">租客房租支付情况</p>
			</el-col>
			<el-col :span="19" class="right-part">
				<span class="search-text">搜索范围</span>
				<el-date-picker v-model="daterange2" type="daterange" range-separator="至"
					start-placeholder="开始时间" end-placeholder="结束时间" style="margin-right: 20px"
					@change="timeChange2">
				</el-date-picker>
				<el-button type="warning" icon="el-icon-search" @click='getIncomeList'>搜索</el-button>
			</el-col>
		</el-row>
		<el-table :data="payList" style="width: 100%" stripe @sort-change="changeSort2">
			<el-table-column type="index"></el-table-column>
			<el-table-column prop="received_date" label="收租日期" :formatter="formatDate" min-width="80"
				sortable="custom">
			</el-table-column>
			<el-table-column prop="received_amount" label="收租金额" min-width="100"
				:formatter="usdFormatter">
			</el-table-column>
			<el-table-column prop="note" label="备注" min-width="400">
			</el-table-column>
		</el-table>
		<el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
			:current-page="queryInfo2.currentPage" :page-sizes="[5, 10, 20, 50]"
			:page-size="queryInfo2.pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="payTotal">
		</el-pagination>
	</div>
</template>

<script>
export default {
	name: 'LeaseInfo',
	props: ['pid'],
	data() {
		return {
			daterange: null,
			queryInfo: {
				query: '',
				pagenum: 1,
				pagesize: 5,
				order: '',
				prop: ''
			},
			leaseList: [],
			total: 0,
			loading1: false,
			daterange2: null,
			loading2: false,
			queryInfo2: {
				date_start: null,
				date_end: null,
				currentPage: 1,
				pageSize: 10,
				order: '',
				prop: ''
			},
			payList: [],
			payTotal: 0,
		};
	},
	created() {
		this.getLeaseInfo();
		this.getIncomeList();
	},
	methods: {
		async getLeaseInfo() {
			this.loading1 = true;
			const {data: res} = await this.$http.get('client/propleases/' + this.pid, {params: this.queryInfo});
			if (res.meta.status !== 200) return this.$message.error('获取租约列表失败！');
			this.leaseList = res.data.lease_infos;
			this.total = res.data.total;
			this.loading1 = false;
		},
		timeChange(value) {
			if (!value) {
				this.queryInfo.date_start = null;
				this.queryInfo.date_end = null;
				this.queryInfo.pagenum = 1;
				this.getLeaseInfo();
			} else {
				this.queryInfo.date_start = value[0];
				this.queryInfo.date_end = value[1];
			}
		},
		timeChange2(value) {
			if (!value) {
				this.queryInfo2.date_start = null;
				this.queryInfo2.date_end = null;
				this.queryInfo2.currentPage = 1;
				this.getIncomeList();
			} else {
				this.queryInfo2.date_start = value[0];
				this.queryInfo2.date_end = value[1];
			}
		},
		changeSort({order, prop}) {
			this.queryInfo.order = order;
			this.queryInfo.prop = prop;
			this.getLeaseInfo();
		},
		changeSort2({order, prop}) {
			this.queryInfo2.order = order;
			this.queryInfo2.prop = prop;
			this.getIncomeList();
		},
		isValidLease(currentLease) {
			const todayDate = this.$dayjs();
			if (this.$dayjs(currentLease.date_start) > todayDate || this.$dayjs(currentLease.date_end) < todayDate) {
				return false;
			} else {
				return true;
			}
		},
		handleSizeChange(newSize) {
			this.queryInfo.pagesize = newSize;
			this.getLeaseInfo();
		},
		handleCurrentChange(newPage) {
			this.queryInfo.pagenum = newPage;
			this.getLeaseInfo();
		},
		handleSizeChange2(newSize) {
			this.queryInfo2.pageSize = newSize;
			this.getLeaseInfo();
		},
		handleCurrentChange2(newPage) {
			this.queryInfo2.currentPage = newPage;
			this.getLeaseInfo();
		},
		async getIncomeList() {
			this.loading2 = true;
			const {data: res} = await this.$http.get('client/propincomes/' + this.pid, {params: this.queryInfo2});
			if (res.meta.status !== 200) return this.$message.error('获取支付列表失败！');
			this.payList = res.data.incomes;
			this.payTotal = res.data.total;
			this.loading2 = false;
		},
		formatDate(r, c, v) {
			if (v) return this.$dayjs(v).format('MMM-DD-YYYY');
			return v;
		},
	}
}
</script>

<style lang="less" scoped>
div {
	.title {
		font-size: 22px;
		line-height: 1.5;
		font-weight: 600;
		margin: 25px 0 20px;
	}
	.operation-bar {
		margin-top: 25px;
		.search-text {
			display: inline-block;
			font-size: 14px;
			font-weight: 600;
			margin-right: 14px;
		}
		.title1 {
			line-height: 40px;
			margin: 0;
		}
		.right-part {
			text-align: right;
		}
	}
	.el-table {
		margin-top: 30px;
	}
	.bottom {
		margin-top: 50px;
	}
}
</style>