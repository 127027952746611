<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item :to="{path: '/income'}">查看收益</el-breadcrumb-item>
			<el-breadcrumb-item>房产详情</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-tabs>
				<el-tab-pane label="房产概况">
					<GeneralDetail :pid="pid" />
				</el-tab-pane>
				<el-tab-pane label="租约详情">
					<LeaseInfo :pid="pid" />
				</el-tab-pane>
				<el-tab-pane label="账单详情">
					<BillInfo :pid="pid" />
				</el-tab-pane>
			</el-tabs>
		</el-card>
	</div>
</template>

<script>
import GeneralDetail from '@/components/GeneralDetail';
import LeaseInfo from '@/components/LeaseInfo';
import BillInfo from '@/components/BillInfo';
export default {
	name: 'PropDetail',
	props: ['pid'],
	components: {GeneralDetail, LeaseInfo, BillInfo},
	created() {
	}
}
</script>